import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import getAxios from "services/axios-get";
import uploadFile from "services/file-uploader";
import ImgGroupInput from "@/components/ImgGroupInput";
import moment from "moment";
import SalespersonGroupInput from "@/components/SalespersonGroupInput";
import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";
import createSignedurl from "../../../services/create-singed-url";

Vue.use(VueAxios, axios, moment);

export default {
  props: {
    vesCategoryList: Array,
    idTypeList: Array,
    coeTypeList: Array,
    coeCatList: Array,
  },
  components: {
    ImgGroupInput,
    SalespersonGroupInput,
  },
  data() {
    return {
      tab: null,
      UsedCarTabs: [
        "Main Edit",
        "PARF Rebate Enquiry",
        "Enquire Transfer Fee",
        "Road Tax",
      ],
      auctionFile: null,
      saleTypes: ["REGULAR", "CONSIGNMENT", "AUCTION"],
      show: false,
      vesPriceName: "",
      radioWatermark: "none",
      crawlerButtonDisable: false,
      editUsedCar: {
        carId: "",
        productTitle: "",
        arfPrice: 0,
        carPlateNumber: "",
        coeCategoryId: "",
        vesCategoryId: "",
        color: "",
        depreciation: 0,
        numOfOwner: 0,
        coeDate: new Date().toISOString().substr(0, 10),
        regDate: new Date().toISOString().substr(0, 10),
        coePrice: 0,
        omvPrice: 0,
        draft: false,
        isDraft: false,
        carModelCode: "",
        mileage: 0,
        notes: "",
        seoMetaTitle: "",
        seoMetaDescription: "",
        roadTax: 0,
        roadTaxExpiry: new Date().toISOString().substr(0, 10),
        salePrice: 0,
        scrapValue: 0,
        manuYear: 0,
        saleType: "",
        auctionFilePath: "",
        idTypeId: "",
        idNum: "",
        // new
        engineNumber: "",
        chassisNumber: "",
        parfEligibility: "",
        parfExpiryDate: "",
        paperValue: 0,
        coeRebateAmount: 0,
        totalRebateAmount: 0,
        sysRemarks: "", //sgcarmart id
        dealerId: null,
        salepersonIds: [],
        importUsed: "",
        offPeakCarFlag: false,
        coeRenewed: false,
      },
      yesNoUnknownList: ["Yes", "No", "Unknown"],
      importUsedList: [
        { code: "SP", title: "Dealer" },
        { code: "CU", title: "Direct owner" },
        { code: "PARTNER", title: "Corporate/Partner" },
      ],
      infoOptions: {
        categoryTypeList: [],
        vesPriceList: [],
      },
      imgs: [],
      dealerNameDisplay: "",
      dealerJSON: { id: null, name: null },
      dealerEntries: [],
      searchDealer: null,
      assignedSalespersonList: [],
      carModelJSON: null,
      entries: [],
      isLoading: false,
      search: null,
      carBrandNameDisplay: "",
      carBrandJSON: null,
      carBrandEntries: [],
      carBrandIsLoading: false,
      carBrandSearch: null,
      carModelNameDisplay: "",
      carModelNameJSON: null,
      carModelNameEntries: [],
      carModelSubNameDisplay: "",
      carModelSubNameJSON: null,
      carModelSubNameEntries: [],
      carModelFinalDisplay: "",
      carModelFinalJSON: null,
      carModelFinalEntries: [],
      editCoeDateModal: false,
      editRegDateModal: false,
      editIntendedDate: false,
      editRoadTaxExpiryModal: false,
      editParfEliDateModal: false,
      coePeriod: "",
      coeRebateAmount: 0,
      totalRebateAmount: 0,
      coeCatPlaceholder: "",
      idTypePlaceHolder: "",
      carCrawlerRecordData: {
        owner_id_type: "",
        owner_id: "",
        vehicle_number: "",
        vehicle_to_be_exported: "",
        intended_deregistration_date: "",
        vehicle_make: "",
        vehicle_model: "",
        primary_colour: "",
        manufacturing_year: "",
        engine_number: "",
        chassis_number: "",
        maximum_power_output: "",
        open_market_value: "",
        original_registration_date: "",
        first_registration_date: "",
        transfer_count: "",
        actual_ARF_paid: "",
        parf_eligibility: "",
        parf_eligibility_expiry_date: "",
        parf_rebate_amount: "",
        coe_expiry_date: "",
        coe_category: "",
        coe_period_years: "",
        pqp_paid: "",
        coe_rebate_amount: "",
        total_rebate_amount: "",
      },
      carEnquireFeeRecordData: {
        intended_deregistration_date: "",
        vehicle_type: "",
        vehicle_attachment_1: "",
        vehicle_scheme: "",
        propellant: "",
        engine_capacity: "",
        maximum_laden_weight: "",
        unladen_weight: "",
        inspection_due_date: "",
        intended_transfer_date: "",
      },
      carRoadTaxData: {
        road_tax_expiry_date: "",
        road_tax_payable: "",
      },
      showProgress: false,
      showProgressForPopulate: false,
      showProgressForParf: false,
      showProgressForEnquireFee: false,
      showProgressForRoadTax: false,
      carId: "",
      today: new Date().toISOString().substr(0, 10),
      intendedDeRegDate: new Date().toISOString().substr(0, 10),
      loadingUpd: false,
      rules: {
        sgcm: [v => !!v || "SGCARMART URL required"],
      },
      rejectedReason: null,
      snackbar: false,
      snackMessage: "",
      crawlerType: {
        parfRebate: "CHECK_REBATE",
        transferFee: "CHECK_TRANSFER_FEE",
        roadTax: "CHECK_ROAD_TAX_PAYABLE",
      },
    };
  },
  mounted() {},
  watch: {
    // Items have already been loaded
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carModelPair?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          searchContent: val,
          status: "APPROVED",
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    carBrandSearch(val) {
      if (val == "") {
        this.resetCarModelSelection();
      }
      if (this.carBrandIsLoading) return;

      this.carBrandIsLoading = true;

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carBrand?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.carBrandEntries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.carBrandIsLoading = false));
    },
  },

  methods: {
    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
    populateMain(carId) {
      var self = this;
      self.showProgressForPopulate = true;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/populateCarCrawlerToCar?carId=${carId}`,
        self.token
      )
        .then(res => {
          if (res.data) {
            const dataObj = res.data.data;
            self.editUsedCar.carModelCode = dataObj.carModelCode;
            self.editUsedCar.coeCategoryId = dataObj.coeCategoryId;
            self.editUsedCar.productTitle = dataObj.productTitle;
            self.editUsedCar.color = dataObj.color;
            self.editUsedCar.arfPrice = dataObj.arfPrice;
            self.editUsedCar.omvPrice = dataObj.omvPrice;
            self.editUsedCar.manuYear = dataObj.manuYear;
            self.editUsedCar.coeDate = dataObj.coeDate
              ? this.convertDateFormat(dataObj.coeDate)
              : new Date().toISOString().substr(0, 10);
            self.editUsedCar.coePrice = dataObj.coePrice;
            self.editUsedCar.numOfOwner = dataObj.numOfOwner;
            self.editUsedCar.regDate = dataObj.regDate
              ? this.convertDateFormat(dataObj.regDate)
              : new Date().toISOString().substr(0, 10);
            self.editUsedCar.engineNumber = dataObj.engineNumber;
            self.editUsedCar.chassisNumber = dataObj.chassisNumber;
            self.editUsedCar.parfEligibility = dataObj.parfEligibility;
            self.editUsedCar.parfExpiryDate = dataObj.parfExpiryDate
              ? this.convertDateFormat(dataObj.parfExpiryDate)
              : new Date().toISOString().substr(0, 10);
            self.editUsedCar.totalRebateAmount = dataObj.totalRebateAmount;

            self.editUsedCar.coePeriod = dataObj.coePeriod;
            self.editUsedCar.coeRebateAmount = dataObj.coeRebateAmount;
            self.editUsedCar.roadTaxExpiry = dataObj.roadTaxExpiry
              ? this.convertDateFormat(dataObj.roadTaxExpiry)
              : new Date().toISOString().substr(0, 10);
            self.editUsedCar.roadTax = dataObj.roadTax;

            self.carBrandNameDisplay = dataObj.carBrandName;
            self.carModelNameDisplay = dataObj.carModelName;
            self.carModelSubNameDisplay = dataObj.carModelSubName;
            self.carModelFinalDisplay =
              dataObj.modelYear +
              " - " +
              dataObj.modelEndYear +
              " (" +
              dataObj.faceliftedYear +
              ")";
            self.tab = "Main Edit";
            self.showProgressForPopulate = false;
          }
        })
        .catch(e => {
          console.log(e);
          self.showProgressForPopulate = false;
        });
    },
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");

      return parsedDate;
    },
    convertCrawlerDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("DDMMYYYY");

      return parsedDate;
    },
    convertDateFormat(date) {
      const dayDate = date.split("+")[0];
      var parsedDate = moment
        .utc(dayDate)
        .local()
        .format("YYYY-MM-DD");
      return parsedDate;
    },
    getCrawlerData(carId) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carCrawlerRecord?carId=${carId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carCrawlerRecordData = res.data.data;
          this.carEnquireFeeRecordData = res.data.data;
          this.carRoadTaxData = res.data.data;
          this.intendedDeRegDate = this.carCrawlerRecordData
            .intended_deregistration_date
            ? this.convertDate(
                this.carCrawlerRecordData.intended_deregistration_date
              )
            : this.intendedDeRegDate;
        }
      });
    },
    getEnquireFeeData(carId) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carEnquireTransferFeeRecord?carId=${carId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carEnquireFeeRecordData = res.data.data;
          if (this.carEnquireFeeRecordData == null) {
            this.carEnquireFeeRecordData = {};
          }
        }
      });
    },
    reRunCrawlerJob(carId) {
      this.showProgress = true;
      this.crawlerButtonDisable = true;
      const intDate = this.convertCrawlerDate(this.intendedDeRegDate);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/reRunCrawlerJob?carId=${carId}&intendedDeRegDate=${intDate}`,
        self.token
      )
        .then(res => {
          if (res.data) {
            this.carCrawlerRecordData = res.data.data;
            this.carEnquireFeeRecordData = res.data.data;
            this.carRoadTaxData = res.data.data;
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.showProgress = false;
          this.crawlerButtonDisable = false;
        });
    },
    reRunJobs(carId, type) {
      if (type === this.crawlerType.parfRebate) {
        this.showProgressForParf = true;
      } else if (type === this.crawlerType.transferFee) {
        this.showProgressForEnquireFee = true;
      } else if (type === this.crawlerType.roadTax) {
        this.showProgressForRoadTax = true;
      }
      this.crawlerButtonDisable = true;
      const intDate = this.convertCrawlerDate(this.intendedDeRegDate);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/reRunCrawlerWithOption?carId=${carId}&crawlerType=${type}&intendedDeRegDate=${intDate}`,
        self.token
      )
        .then(res => {
          if (res.data) {
            type === this.crawlerType.parfRebate
              ? (this.carCrawlerRecordData = res.data.data)
              : this.carCrawlerRecordData;
            type === this.crawlerType.transferFee
              ? (this.carEnquireFeeRecordData = res.data.data)
              : this.carEnquireFeeRecordData;
            type === this.crawlerType.roadTax
              ? (this.carRoadTaxData = res.data.data)
              : this.carRoadTaxData;
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.showProgressForParf = false;
          this.showProgressForEnquireFee = false;
          this.showProgressForRoadTax = false;
          this.crawlerButtonDisable = false;
        });
    },
    carBrandChange(carBrand) {
      const carBrandId = carBrand.id;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelModelName?carBrandId=${carBrandId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelNameEntries = res.data.data;
        }
      });
    },
    carModelNameChange(carBrand, carModelName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelSubModelName?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelSubNameEntries = res.data.data;
        }
      });
    },

    carModelSubNameChange(carBrand, carModelName, carModelSubName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}&submodelName=${encodeURIComponent(carModelSubName)}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getCarModel?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelFinalEntries = res.data.data;
        }
      });
    },

    carModelFinalChange(carModelCodeSelected) {
      this.editUsedCar.carModelCode = carModelCodeSelected;
    },
    test() {
      const xx = this.assignedSalespersonList.map(m => m.id);
      console.log(xx);
    },

    resetCarModelSelection() {
      this.editUsedCar.carModelCode = null;
      this.carModelNameJSON = "";
      this.carModelSubNameJSON = "";
      this.carModelFinalJSON = "";
      this.carModelNameEntries = [];
      this.carModelSubNameEntries = [];
      this.carModelFinalEntries = [];
    },
    async open(item) {
      this.carCrawlerRecordData = {};
      this.carEnquireFeeRecordData = {};
      this.carRoadTaxData = {};
      this.getCrawlerData(item.id);
      this.getEnquireFeeData(item.id);
      this.rejectedReason = item.rejectedReason;
      this.carId = item.id;
      this.idTypePlaceHolder = item.idType;
      this.editUsedCar.coePeriod = item.coePeriod;
      this.editUsedCar.coeRebateAmount = item.coeRebateAmount;
      this.editUsedCar.totalRebateAmount = item.totalRebateAmount;
      this.editUsedCar.engineNumber = item.engineNumber;
      this.editUsedCar.chassisNumber = item.chassisNumber;
      this.editUsedCar.parfEligibility = item.parfEligibility;
      this.editUsedCar.paperValue = item.paperValue;
      this.carModelFinalDisplay =
        item.carModelYear +
        " - " +
        item.carModelEndYear +
        " (" +
        item.carModelFaceliftedYear +
        ")";
      this.dealerNameDisplay = item.dealerName;
      this.dealerJSON = { id: item.dealerId, name: item.dealerName };
      this.carModelSubNameDisplay = item.carModelSubName;
      this.carModelNameDisplay = item.carModelName;
      this.carBrandNameDisplay = item.carBrandName;
      this.editUsedCar.idNum = item.idNum;
      this.editUsedCar.idTypeId = null;
      this.editUsedCar.saleType = item.saleType;
      this.editUsedCar.auctionFilePath = item.auctionFilePath;
      this.editUsedCar.carId = item.id;
      this.editUsedCar.numOfOwner = item.numOfOwner;
      this.editUsedCar.productTitle = item.productTitle;
      this.editUsedCar.arfPrice = item.arfPrice;
      this.editUsedCar.carPlateNumber = item.carPlateNumber;
      this.editUsedCar.color = item.color;
      this.editUsedCar.depreciation = item.depreciation;
      this.editUsedCar.omvPrice = item.omvPrice;
      this.editUsedCar.coePrice = item.coePrice;
      this.editUsedCar.importUsed = item.importUsed;
      this.editUsedCar.offPeakCarFlag =
        item.offPeakCarFlag === 1 ? true : false;
      this.editUsedCar.coeRenewed = item.coeRenewed === 1 ? true : false;

      const date = this.convertDate(new Date());
      this.editUsedCar.parfExpiryDate = item.parfExpiryDate
        ? this.convertDateFormat(item.parfExpiryDate)
        : date;
      this.editUsedCar.coeDate = item.coeDate
        ? this.convertDateFormat(item.coeDate)
        : date;
      this.editUsedCar.regDate = item.regDate
        ? this.convertDateFormat(item.regDate)
        : date;
      this.editUsedCar.roadTaxExpiry = item.roadTaxExpiry
        ? this.convertDateFormat(item.roadTaxExpiry)
        : date;

      this.handleCarModelSelect({
        name: item.carModel,
        code: item.carModelCode,
      });
      this.editUsedCar.draft = item.draft;
      this.editUsedCar.isDraft = item.isDraft;
      this.editUsedCar.mileage = item.mileage;
      this.editUsedCar.carModelCode = item.carModelCode;
      this.editUsedCar.notes = item.notes;
      this.editUsedCar.seoMetaTitle = item.seoMetaTitle;
      this.editUsedCar.seoMetaDescription = item.seoMetaDescription;
      this.editUsedCar.roadTax = item.roadTax;
      this.editUsedCar.salePrice = item.salePrice;
      this.editUsedCar.scrapValue = item.scrapValue;
      this.editUsedCar.manuYear = item.manuYear;
      this.editUsedCar.sysRemarks = item.sysRemarks;
      this.imgs = [...item.displayImgs].map(url => ({
        url,
      }));
      // this.coeCatPlaceholder = item.coeCode;
      const coeSelected = this.coeCatList.find(
        c => c.id === item.coeCategoryId
      );
      this.editUsedCar.coeCategoryId = coeSelected;
      this.show = true;
    },
    handleCarModelSelect(carModel) {
      let self = this;
      self.carModelJSON = carModel;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeVesPair?carModelCode=${carModel.code}`
      )
        .then(res => res.json())
        .then(res => {
          // let { name: vesCatName, value: coeCode } = res.data;
          let { name: vesCatName } = res.data;
          if (vesCatName) {
            self.editUsedCar.vesCategoryId = self.vesCategoryList.find(
              v => v.name === vesCatName
            ).id;
          }

          // if (coeCode) {
          //   self.editUsedCar.coeCategoryId = self.coeCatList.find(
          //     c => c.name === coeCode
          //   ).id;
          // }
        })
        .catch(err => console.error(err));
    },
    async doEditUsedCar() {
      const self = this;

      // this.radioWatermark == "watermark" ? !self.loadingUpd : self.loadingUpd;
      try {
        if (this.$refs.form.validate()) {
          self.loadingUpd = true;
          this.editUsedCar.dealerId = this.dealerJSON
            ? this.dealerJSON.id
            : null;
          this.editUsedCar.salepersonIds = this.assignedSalespersonList.map(
            m => m.id
          );
          if (self.auctionFile) {
            await uploadFile(self.auctionFile).then(res => {
              self.editUsedCar.auctionFilePath = res.data.data;
            });
          }

          self.editUsedCar.watermarkFlag =
            this.radioWatermark == "watermark" ? 1 : 0;
          // const isObscure = this.radioWatermark == "watermark" ? true : false;
          // const imgResList = [...(await self.$refs.imgs[0].upload(isObscure))];

          const imgResLists = self.$refs.imgs[0].imgs;
          let uploadUrlImageName = [];
          let img;
          for (let i = 0; i < imgResLists.length; i++) {
            img = imgResLists[i];
            if (!img.file) {
              const splitImageUrl = new URL(img.url);
              const imageUrl = splitImageUrl
                ? splitImageUrl.origin + splitImageUrl.pathname
                : img.url;
              if (
                imageUrl.indexOf("_wm.") === -1 &&
                self.editUsedCar.watermarkFlag
              ) {
                const uploadUrl = await createSignedurl(imageUrl, this.token);
                if (uploadUrl === "error") {
                  this.showSnackBar("Edit Car Failed");
                  this.loadingUpd = false;
                }
                const watermarkedImage = await createWatermark(imageUrl);
                uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
                const imageUpload = await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                if (imageUpload === "error") {
                  this.showSnackBar("Edit Car Failed");
                  this.loadingUpd = false;
                }
              } else {
                uploadUrlImageName.push(imageUrl);
              }
            } else if (img.file) {
              const uploadUrl = await getAxios(
                `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
                {
                  format: "png",
                  waterMark: self.editUsedCar.watermarkFlag,
                },
                this.token
              );
              if (self.editUsedCar.watermarkFlag) {
                const watermarkedImage = await createWatermark(img.file);
                if (watermarkedImage == "error") {
                  this.showSnackBar("Failed to upload water mark");
                  this.loadingUpd = false;
                } else {
                  const imageUpload = await uploadSignedImage(
                    watermarkedImage,
                    uploadUrl.data.waterMarkedUrl
                  );
                  if (imageUpload === "error") {
                    this.showSnackBar("Edit Car Failed");
                    this.loadingUpd = false;
                  }
                }
                uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
              } else {
                uploadUrlImageName.push(uploadUrl.data.originalName);
                const imageUpload = await uploadSignedImage(
                  img.file,
                  uploadUrl.data.originalUrl
                );
                if (imageUpload === "error") {
                  this.showSnackBar("Edit Car Failed");
                  this.loadingUpd = false;
                }
              }
            }
          }
          if (self.editUsedCar.coeCategoryId == "")
            self.editUsedCar.coeCategoryId = null;
          if (self.editUsedCar.vesCategoryId == "")
            self.editUsedCar.vesCategoryId = null;
          self.editUsedCar.defaultImgPath = uploadUrlImageName.shift();
          self.editUsedCar.displayImgs = uploadUrlImageName;
          if (
            typeof self.editUsedCar.coeCategoryId === "object" &&
            self.editUsedCar.coeCategoryId !== null
          )
            self.editUsedCar.coeCategoryId = self.editUsedCar.coeCategoryId.id;

          self.editUsedCar.salePrice = self.convertToNumberOnly(
            self.editUsedCar.salePrice
          );
          self.editUsedCar.mileage = self.convertToNumberOnly(
            self.editUsedCar.mileage
          );
          self.editUsedCar.omvPrice = self.convertToNumberOnly(
            self.editUsedCar.omvPrice
          );
          self.editUsedCar.arfPrice = self.convertToNumberOnly(
            self.editUsedCar.arfPrice
          );
          self.editUsedCar.coePrice = self.convertToNumberOnly(
            self.editUsedCar.coePrice
          );
          self.editUsedCar.roadTax = self.convertToNumberOnly(
            self.editUsedCar.roadTax
          );
          self.editUsedCar.depreciation = self.convertToNumberOnly(
            self.editUsedCar.depreciation
          );
          self.editUsedCar.coeRebateAmount = self.convertToNumberOnly(
            self.editUsedCar.coeRebateAmount
          );
          self.editUsedCar.totalRebateAmount = self.convertToNumberOnly(
            self.editUsedCar.totalRebateAmount
          );

          self.editUsedCar.watermarkFlag = 0;
          self.editUsedCar.offPeakCarFlag = self.editUsedCar.offPeakCarFlag
            ? 1
            : 0;
          self.editUsedCar.coeRenewed = self.editUsedCar.coeRenewed ? 1 : 0;
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarUsed`,
            self.editUsedCar,
            self.token
          )
            .then(res => {
              if (res.status == 201) {
                self.$emit("error", res.data.message);
                self.loadingUpd = false;
                return;
              }
              if (res.data) {
                self.loadingUpd = self.show = false;
                self.$emit("edit");
                self.loadingUpd = false;
              }
            })
            .finally(() => (self.loadingUpd = false));
        }
      } catch (e) {
        self.$emit("error", e);
        self.loadingUpd = false;
      }
    },

    convertToNumberOnly(value) {
      var regex = /[^0-9.]/g;
      value = value ? value : 0;
      return parseFloat(value.toString().replace(regex, ""));
    },
  },
};
